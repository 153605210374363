import React from "react";

export default function NotFound() {
	return (
		<div style={{ textAlign: "center" }}>
			<img
				style={{ width: "40%", height: "500px" }}
				src="https://img.freepik.com/free-vector/404-error-with-landscape-concept-illustration_114360-7888.jpg"
				alt="Not Found"
			/>
		</div>
	);
}
